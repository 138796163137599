import amplitude from 'amplitude-js';

/* global window */

// This is deprecated in favor of trackProductAnalytics.

export const logAmplitudeEvent = ({
  eventName = null,
  eventProperties = null,
  eventTransport = 'http',
}) => {
  if (
    !window.CLIENT_INFO ||
    window.CLIENT_INFO.amplitudeLiveTracking === false ||
    window.CLIENT_INFO.amplitudeKey === ''
  ) {
    return;
  }

  // Fallback for IE's lack of browser beacon support.
  //   If navigator.sendBeacon is not available send via standard ajax request.
  //   Tracking amplitude events when navigating to a new page exposes a race condition,
  //   however, we don't want to slow down a Site Member's experience for the sake of event
  //   tracking. We're willing to make this trade-off for IE until we sunset IE support in Site.
  if (eventTransport === 'beacon' && typeof window.navigator.sendBeacon === 'undefined') {
    eventTransport = 'http';
  }

  amplitude.getInstance().init(window.CLIENT_INFO.amplitudeKey, window.CLIENT_INFO.amplitudeId);
  amplitude.getInstance().setTransport(eventTransport);

  amplitude.getInstance().logEvent(eventName, eventProperties);
};
